import React from 'react'
import { Grid } from "@material-ui/core";
import { Link } from 'gatsby';
import RSSFeed from '../../../assets/RSS Feed.png';
import Spotify from '../../../assets/Spotify.png';
import ApplePodcast from '../../../assets/Apple Podcast.png';
import AmazonMusic from '../../../assets/Amazon Music.png';
import GooglePodcast from '../../../assets/Google Podcast.png';
import iHeartRadio from '../../../assets/iHeartRadio.png';
import './PodcastContentFeed.scss'
import Container from '@material-ui/core/Container';

export default function PodcastContentFeed() {
    return (
        <>
        <Container maxWidth="xl" className='container-padding-none'>
            <Grid container className='podcast-content-feeds'>

                {/* Feeds Starts */}
               
                <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                    <div className='feeds-container'>
                      <p className='feeds-main-txt'>Feeds:</p>
                    </div>
                </Grid>
                
                <Grid container className='feeds-image' item xl={11} lg={5} md={7} sm={10} xs={9}>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={'/'} role="link" tabIndex="0">
                            <img src={RSSFeed} alt="RSS Feed" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={'/'} role="link" tabIndex="0">
                            <img src={Spotify} alt="Spotify" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={'/'} role="link" tabIndex="0">
                            <img src={ApplePodcast} alt="Apple Podcast" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={'/'} role="link" tabIndex="0">
                            <img src={AmazonMusic} alt="Amazon Music" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={'/'} role="link" tabIndex="0">
                            <img src={GooglePodcast} alt="Google Podcast" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={'/'} role="link" tabIndex="0">
                            <img src={iHeartRadio} alt="iHeartRadio" />
                        </Link>
                    </Grid>
                </Grid>
                <Grid item xl={0} lg={6} md={4} sm={1} xs={0}></Grid>
               
                {/* Feeds Ends */}
            </Grid>
        </Container>
        </>
    )
}


