import React from 'react'
import { Grid } from "@mui/material";
import GuestOne from "../../../assets/Guest_1.png"
import GuestTwo from "../../../assets/Guest_2.png"
import HostOne from "../../../assets/Host_1.png"
import './PodcastContentTiles.scss'
import Container from '@material-ui/core/Container';

export default function PodcastContentTiles() {
  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container className="podcast-content-tiles-cls">
        {/* **********************    Podcast Content Tiles Starts    ********************** */}

        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
           <h2 className='tiles-title'>our guests</h2>
        </Grid>

        {/* Guest One */}
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className='tiles-section-one'>
        
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Grid className='desk-name'>
               <h3 className='sub-title'>Colin Phaseus</h3>
            </Grid>
             <p className='tiles-desc-one'>Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore
                Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd
                Gubergren, No Sea Takimata Sanctus Est Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing
                Elitr, Sed Diam.</p>
          </Grid>
          <Grid item container xl={3} lg={3} md={3} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={8} xs={6} className='mob-name'>
            <h3 className='sub-title'>Colin Phaseus</h3>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={4} xs={6} className='dot-matrix-img-right'>
              <img src={GuestOne} alt="Guest One" className='tiles-imgs' />
            </Grid>
          </Grid>
        </Grid>


        {/* Guest Two */}
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className='tiles-two-bottom-padding'>
           <Grid item container xl={3} lg={3} md={3} sm={12} xs={12} className='tiles-section-two'>
            <Grid item xl={12} lg={12} md={12} sm={8} xs={6} className='mob-name'>
               <h3 className='sub-title'>Andrea Denson</h3>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={4} xs={6} className='dot-matrix-img-left'>
              <img src={GuestTwo} alt="Guest Two" className='tiles-imgs' />
            </Grid>
          </Grid>

          <Grid item container xl={9} lg={9} md={9} sm={12} xs={12} className='desc-two-container'>
           <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className='desk-name'>
              <h3 className='sub-title'>Andrea Denson</h3>
            </div>
              <p className='tiles-desc-two'>Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt
                Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet
                Clita Kasd Gubergren, No Sea Takimata Sanctus Est Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet, Consetetur
                Sadipscing Elitr, Sed Diam.</p>
            </Grid>
          </Grid>
        </Grid>

        {/* Our Host */}
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className='host-container'>
            <h2 className='tiles-title'>our host</h2>
          </Grid>
        </Grid>

        {/* Host One */}
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className='tiles-section-one'>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Grid  className='desk-name'>
              <h3 className='sub-title'>Neil Finsbury</h3>
            </Grid>
             <p className='tiles-desc-one'>Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore
                Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd
                Gubergren, No Sea Takimata Sanctus Est Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing
                Elitr, Sed Diam.</p>
           
          </Grid>
          <Grid item container xl={3} lg={3} md={3} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={8} xs={6} className='mob-name'>
              <h3 className='sub-title'>Neil Finsbury</h3>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={4} xs={6} className='dot-matrix-img-right'>
              <img src={HostOne} alt="Host One" className='tiles-imgs' />
            </Grid>
          </Grid>
        </Grid>
        {/* **********************    Podcast Content Tiles Ends    ********************** */}
      </Grid>
      </Container>
    </>
  )
}


