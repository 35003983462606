import React from 'react'
import { Grid } from "@mui/material";
import OtherEpisodes from "../../../assets/Other Episodes.png"
import OtherEpisodesBG from "../../../assets/Other Episodes BG.png"
import './PodcastContentEpisodes.scss'
import Container from '@material-ui/core/Container';

export default function PodcastContentEpisodes() {
  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container className="podcast-content-episodes">
        {/* **********************    Hero Image With Text    ********************** */}
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={7} lg={7} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
            <h2 className='episodes-title'>other episodes</h2>
          </Grid>
        </Grid>

        <Grid container className='epis-container'>
          <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
            {/* <div className='epis-vert-alig-img'>
            <img src={OtherEpisodesBG} alt="Other Episode BG" className='epis-bubble-img' />
            </div> */}
          </Grid>
          <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
            <img src={OtherEpisodes} alt="Other Episodes" className='epis-img' />
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  )
}


