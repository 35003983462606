import React from 'react'
import Layout from '../../staticcomponents/layout'
import './podcastcontent.scss'
import PodcastContentHeader from './podcastcontentheader'
import PodcastContentTiles from './podcastcontenttiles'
import PodcastContentFeed from './podcastcontentfeed'
import PodcastContentEpisodes from './podcastcontentepisodes'
import CategorySocialMedia from '../../staticcomponents/category-social media'




export default function PodcastContent() {
    return (
        <>
            <Layout>
                <div className='podcast-content-main'>
                    <div className='bg-donuts-podcast-content'>
                        <PodcastContentHeader />
                    </div>
                </div>
                <PodcastContentTiles />
                <PodcastContentFeed />
                <div className="capsules-bg-left">
                <PodcastContentEpisodes />
                </div>
                <CategorySocialMedia />
               
            </Layout>
        </>
    )
}