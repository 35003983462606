import React from 'react';
import { Grid } from "@mui/material";
import hero_image from '../../../assets/Podcast.png';
import Podcast_thumbnail from '../../../assets/Podcast thumbnail.png';
import './PodcastContentHeader.scss';
import Container from '@material-ui/core/Container';

export default function PodcastContentHeader() {
  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container className="podcast-content-header-cls">
        {/* **********************  Podcast Content Header Starts  ********************** */}
        <Grid container className="Text-with-hero-img">
        <Grid container item xl={5} lg={5} md={12} sm={12} xs={12}>
        <Grid item xl={0} lg={0} md={1} sm={0} xs={0}></Grid>
          <Grid item xl={12} lg={12} md={11} sm={12} xs={12}>
            <div className="header-text">
            <div className="header-text-div">
              <img className='header-image' src={Podcast_thumbnail} alt="Podcast thumbnail" />
              <p className="header-date">
                PODCAST / 22 JUN 2022
              </p>
              <h1 className="header-title">
                temporary living challenges today
              </h1>
            </div>
            </div>
          </Grid>
          </Grid>

          <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
            <div className="header-heroimg">
            <img src={hero_image} alt="hero image" />
            {/* <iframe
              src={hero_image}
              width="834"
              height="387"
              // type="text/html"
              frameBorder="0"
              // allowTransparency="true"
            /> */}
            </div>
          </Grid>

        </Grid>

        {/* Paragraph */}
        <Grid container className='rich-text-para'>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <p>Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well,
              many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over
              200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re
              based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily
              life.</p>
          </Grid>
        </Grid>
        {/* **********************  Podcast Content Header Ends  ********************** */}
      </Grid>
      </Container>



      
    </>
  )
}


